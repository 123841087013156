import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import router from "./router";
import { SnackbarProvider } from "./context/SnackbarContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <SnackbarProvider>
    <RouterProvider router={router} />
  </SnackbarProvider>
  // </React.StrictMode>G
);
