import { Button, Modal } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";

export default function RequestMoreTime({ show, onDecline, onAccept }) {
  return (
    <Modal open={show} onClose={onDecline}>
      <div className="bg-white absolute top-2/4 left-2/4 w-[500px] max-w-11/12 ring-1 ring-gray-900/5 -translate-y-1/2 -translate-x-1/2 shadow rounded-lg p-5 outline-none">
        <h1 className="text-lg mb-5 font-medium">More time request</h1>

        <Formik
          initialValues={{ timeInMinutes: 10 }}
          validationSchema={Yup.object().shape({
            timeInMinutes: Yup.number()
              .min(1, "Time should be at least 1 min")
              .required("Time is required"),
          })}
          onSubmit={onAccept}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Field
                component={TextField}
                label="Time in minutes"
                name="timeInMinutes"
                type="number"
                size="small"
                fullWidth
              />

              <div className="flex justify-end gap-3 mt-3">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={onDecline}
                  disabled={isSubmitting}
                >
                  Decline
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Accept
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
