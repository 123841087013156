import { useEffect, useState } from "react";
import socket from "../socket";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

var speechRecognition;

export default function Transcription({ record }) {
  const [transcripts, setTranscripts] = useState([]);

  useEffect(() => {
    function onTranscript(data) {
      setTranscripts((prev) => [data, ...prev]);
    }

    socket.on("transcript", onTranscript);

    return () => {
      socket.off("transcript", onTranscript);
    };
  }, []);

  useEffect(() => {
    speechRecognition = new SpeechRecognition();
    speechRecognition.lang = "en-US";
    speechRecognition.continuous = true;
    speechRecognition.interimResults = false;

    speechRecognition.start();

    speechRecognition.onresult = (event) => {
      if (record) {
        const result = event.results[event.resultIndex];
        if (result.isFinal) {
          const transcript = result[0].transcript;

          setTranscripts((prev) => [
            { name: "Me", transcript: transcript },
            ...prev,
          ]);

          if (socket.connected) socket.emit("transcript", transcript);
        }
      }
    };

    return () => {
      if (speechRecognition) {
        speechRecognition.stop();
      }
    };
  }, [record]);

  return (
    <div className="h-48 bg-white ring-1 ring-gray-300 rounded-lg p-5 flex-none flex flex-col">
      <h1>Transcription</h1>

      <div className="mx-3 my-1 overflow-auto flex-1">
        {transcripts.map((t, i) => (
          <p key={i}>
            <strong>{t.name}: </strong>
            {t.transcript}
          </p>
        ))}
      </div>
    </div>
  );
}
