import {
  ContentCopy,
  MicNoneOutlined,
  MicOffOutlined,
  Videocam,
  VideocamOff,
} from "@mui/icons-material";
import { Avatar, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MeetingControls({
  meetingId,
  name,
  host,
  micOn,
  videoOn,
  toggleMic,
  toggleVideo,
  showStartButton,
  onStartMeeting,
  onRequestMoreTime,
  showRequestMoreTimeButton,
}) {
  const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText(
      window.location.origin + "/join/" + meetingId
    );
  };

  const handleLeave = () => navigate("/");

  return (
    <div className="bg-white ring-1 ring-gray-300 rounded-lg p-4 flex lg:flex-row flex-col justify-between items-center flex-none gap-4">
      <div className="flex gap-3 items-center">
        <Avatar sx={{ bgcolor: "deepskyblue" }}>{name[0]}</Avatar>
        <p className="text-gray-500 uppercase">{name}</p>
      </div>

      <div className="flex gap-3 items-center">
        <IconButton onClick={handleCopy}>
          <ContentCopy />
        </IconButton>
        <IconButton onClick={toggleMic}>
          {micOn ? (
            <MicNoneOutlined fontSize="inherit" />
          ) : (
            <MicOffOutlined fontSize="inherit" />
          )}
        </IconButton>
        <IconButton onClick={toggleVideo}>
          {videoOn ? (
            <Videocam fontSize="inherit" />
          ) : (
            <VideocamOff fontSize="inherit" />
          )}
        </IconButton>
      </div>

      <div>
        {showStartButton && host && (
          <Button variant="contained" onClick={onStartMeeting}>
            Start
          </Button>
        )}
        {showRequestMoreTimeButton && (
          <Button variant="contained" onClick={onRequestMoreTime}>
            {host ? "Add More Time" : "Request More Time"}
          </Button>
        )}

        {!showRequestMoreTimeButton && !showStartButton && (
          <Button
            variant="contained"
            color="error"
            onClick={handleLeave}
            sx={{ paddingX: 3 }}
          >
            Leave
          </Button>
        )}
      </div>
    </div>
  );
}
