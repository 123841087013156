import Video from "./Video";

export default function VideoContainer({ currentTurnEmail, peers }) {
  return (
    <div
      className={`flex-1 overflow-auto gap-5 grid grid-cols-1 p-3 ${
        peers.length > 1 ? "md:grid-cols-2" : ""
      } ${peers.length > 4 ? "lg:grid-cols-3" : ""} ${
        peers.length > 6 ? "xl:grid-cols-4" : ""
      }`}
    >
      {peers.map((p, i) => (
        <Video
          key={i}
          peer={p}
          local={i === 0}
          current={currentTurnEmail === p.user.email}
        />
      ))}
    </div>
  );
}
