import { Button } from "@mui/material";
import axios from "axios";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "../context/SnackbarContext";

import logo from "../assets/app_icon.jpeg";

export default function Join() {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const { showSnackbar } = useSnackbar();

  const [meeting, setMeeting] = useState();

  const meetingId = location.state?.meetingId || id;

  const handleJoin = ({ email }, { setSubmitting }) => {
    let participant;

    if (email === meeting.hostEmail) {
      participant = {
        name: meeting.hostName,
        email: meeting.hostEmail,
      };
    } else {
      participant = meeting.participants.find((p) => p.email === email);
    }

    if (participant) {
      navigate("/meeting", {
        state: {
          meetingId: meetingId,
          name: participant.name,
          email: participant.email,
          host: email === meeting.hostEmail,
        },
      });
    } else {
      showSnackbar("You are not allowed to join this meeting");
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (!location.state?.meetingId && !id) navigate("/", { replace: true });

    const meetingId = location.state?.meetingId || id;

    if (location.state?.meetingId) {
      setMeeting(location.state);
    } else {
      axios
        .get(process.env.REACT_APP_API_ENPOINT + "meetings/" + meetingId)
        .then((res) => {
          if (res.data) {
            const { success, data } = res.data;

            if (success) {
              setMeeting(data);
            } else {
              navigate("/");
            }
          }
        })
        .catch(() => {
          navigate("/");
        });
    }
  }, [id, location, navigate]);

  return (
    <div className="backdrop-blur w-screen h-screen flex flex-col gap-6 pt-24">
      <img
        src={logo}
        className="w-64 rounded-lg shadow self-center"
        alt="logo"
      />

      <div className="bg-white shadow-xl ring-1 ring-gray-900/5 mx-auto min-w-80 lg:w-4/12 md:w-8/12 max-w-10/12 p-7 pt-10 rounded">
        <h1 className="text-2xl mb-5">
          Enter your details{" "}
          <span className="text-gray-500 text-sm">
            (Meeting ID: {meetingId}):
          </span>
        </h1>

        <Formik
          initialValues={{ name: "", email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Invalid email").required("Required"),
          })}
          onSubmit={handleJoin}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <div className="flex flex-col gap-3">
                <Field
                  component={TextField}
                  label="Email"
                  name="email"
                  size="small"
                  disabled={isSubmitting}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Join
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
