import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import socket from "../socket";
import Notes from "../components/Notes";
import MeetingControls from "../components/MeetingControls";
import VideoContainer from "../components/VideoContainer";
import Transcription from "../components/Transcription";
import { useSnackbar } from "../context/SnackbarContext";
import RequestMoreTime from "../components/RequestMoreTime";
import MeetingHeader from "../components/MeetingHeader";

let peerConfiguration = {
  iceServers: [
    ...process.env.REACT_APP_STUN_SERVERS.split(",").map((url) => ({ url })),
    ...process.env.REACT_APP_TURN_SERVERS.split(",").map((server) => {
      const [url, username, credential] = server.split(";");
      return { url, username, credential };
    }),
  ],
};

export default function Meeting() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [localStream, setLocalStream] = useState(null);
  const [localControl, setLocalControl] = useState({
    micOn: true,
    videoOn: true,
  });
  const [peers, setPeers] = useState([]);
  const [iceCandidates, setIceCandidate] = useState({});
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [turn, setTurn] = useState(null);
  const [requestedMoreTime, setRequestedMoreTime] = useState(null);

  const { meetingId, name, email, host } = location?.state || {};

  const supportSpeechRecognition =
    "SpeechRecognition" in window || "webkitSpeechRecognition" in window;

  useEffect(() => {
    if (!location.state) navigate("/", { replace: true });
    else {
      async function enableStream() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });
          setLocalStream(stream);
        } catch (err) {
          showSnackbar("Unable to access your Webcam and Microphone");
        }
      }

      if (!localStream) {
        enableStream();
      } else {
        return function cleanup() {
          localStream.getTracks().forEach((track) => {
            track.stop();
          });
        };
      }
    }
  }, [localStream, location, navigate, showSnackbar]);

  useEffect(() => {
    if (localStream) {
      if (!socket.connected) socket.connect();

      function onConnect() {
        setIsConnected(true);
        socket.emit("join", {
          meetingId: meetingId,
          name: name,
          email: email,
        });
      }

      function onStart() {
        setMeetingStarted(true);

        const audioTrack = localStream
          .getTracks()
          .find((t) => t.kind === "audio");

        if (audioTrack) audioTrack.enabled = false;
      }

      function onTurn(turn) {
        setTurn(turn);

        const audioTrack = localStream
          .getTracks()
          .find((t) => t.kind === "audio");
        if (audioTrack) {
          audioTrack.enabled =
            turn.current.email === email && localControl.micOn;
        }
      }

      function onRequestMoreTime(id) {
        if (id === socket.id) {
          setRequestedMoreTime({ id: id, name: name, email: email });
        } else {
          setRequestedMoreTime(peers.find((p) => p.user.id === id)?.user);
        }
      }

      function onRequestMoreTimeAccepted({ of, timeInMinutes, endAt }) {
        if (requestedMoreTime) {
          setRequestedMoreTime(null);
          setTurn((prev) => ({ ...prev, endAt: endAt }));
        }
      }

      function onRequestMoreTimeDeclined() {
        if (requestedMoreTime) {
          setRequestedMoreTime(null);
        }
      }

      async function onAddPeer({ id, name, email }) {
        const peerConnection = new RTCPeerConnection(peerConfiguration);
        const mediaStream = new MediaStream();

        var firstCandidate = true;

        localStream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, localStream);
        });

        peerConnection.ontrack = (e) => {
          e.streams[0].getTracks().forEach((track) => {
            mediaStream.addTrack(track);
          });
        };

        peerConnection.onicecandidate = async (event) => {
          if (event.candidate) {
            if (firstCandidate) {
              socket.emit("offer", {
                for: id,
                offer: peerConnection.localDescription,
              });

              firstCandidate = false;
            }

            socket.emit("candidate", {
              for: id,
              candidate: event.candidate,
            });
          }
        };

        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        const peer = {
          user: { id, name, email },
          peerConnection: peerConnection,
          mediaStream: mediaStream,
        };

        setPeers((prevPeers) => [...prevPeers, peer]);
      }

      async function onOffer({ from: id, name, email, offer }) {
        const peerConnection = new RTCPeerConnection(peerConfiguration);
        const mediaStream = new MediaStream();

        var firstCandidate = true;

        localStream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, localStream);
        });

        peerConnection.ontrack = (e) => {
          e.streams[0].getTracks().forEach((track) => {
            mediaStream.addTrack(track);
          });
        };

        peerConnection.onicecandidate = (e) => {
          if (e.candidate) {
            if (firstCandidate) {
              socket.emit("answer", {
                for: id,
                answer: peerConnection.localDescription,
              });
              firstCandidate = false;
            }

            socket.emit("candidate", {
              for: id,
              candidate: e.candidate,
            });
          }
        };

        await peerConnection.setRemoteDescription(offer);

        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        const peer = {
          user: { id, name, email },
          peerConnection: peerConnection,
          mediaStream: mediaStream,
        };

        setPeers((prevPeers) => [...prevPeers, peer]);
      }

      function onAnswer({ from, answer }) {
        setPeers((prevPeers) => {
          const peers = [...prevPeers];
          const peer = peers.find((p) => p.user.id === from);
          if (!peer.peerConnection.currentRemoteDescription) {
            try {
              peer.peerConnection.setRemoteDescription(answer);
            } catch (err) {}
          }
          return peers;
        });
      }

      function onRemovePeer(id) {
        setPeers([...peers.filter((p) => p.user.id !== id)]);
      }

      function onCandidate({ from, candidate }) {
        const candidates = { ...iceCandidates };
        if (!candidates[from]) candidates[from] = [];
        candidates[from].push(candidate);
        setIceCandidate(candidates);
      }

      function onDisconnect() {
        setIsConnected(false);
      }

      socket.on("connect", onConnect);
      socket.on("started", onStart);
      socket.on("turn", onTurn);
      socket.on("requestedMoreTime", onRequestMoreTime);
      socket.on("moreTimeRequestAccepted", onRequestMoreTimeAccepted);
      socket.on("moreTimeRequestDecline", onRequestMoreTimeDeclined);
      socket.on("addPeer", onAddPeer);
      socket.on("removePeer", onRemovePeer);
      socket.on("offer", onOffer);
      socket.on("answer", onAnswer);
      socket.on("candidate", onCandidate);
      socket.on("disconnect", onDisconnect);

      return () => {
        socket.off("connect", onConnect);
        socket.off("started", onStart);
        socket.off("turn", onTurn);
        socket.on("requestedMoreTime", onRequestMoreTime);
        socket.off("moreTimeRequestAccepted", onRequestMoreTimeAccepted);
        socket.off("moreTimeRequestDecline", onRequestMoreTimeDeclined);
        socket.off("addPeer", onAddPeer);
        socket.off("offer", onOffer);
        socket.off("answer", onAnswer);
        socket.off("removePeer", onRemovePeer);
        socket.off("candidate", onCandidate);
        socket.off("disconnect", onDisconnect);
      };
    }
  }, [
    localControl.micOn,
    peers,
    iceCandidates,
    localStream,
    name,
    email,
    meetingId,
    requestedMoreTime,
  ]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      const updatedIceCandidates = { ...iceCandidates };
      const keys = Object.keys(updatedIceCandidates);

      if (keys.length > 0) {
        const updatedPeers = [...peers];

        keys.forEach((from) => {
          const peer = updatedPeers.find((p) => p.user.id === from);

          if (peer) {
            updatedIceCandidates[from].forEach((candidate) => {
              peer.peerConnection.addIceCandidate(candidate);
            });

            delete updatedIceCandidates[from];
          }
        });

        setIceCandidate(updatedIceCandidates);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [iceCandidates, peers]);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);

  const toggleMic = () => {
    const stream = localStream;
    const audioTrack = stream.getTracks().find((t) => t.kind === "audio");

    setLocalControl((prev) => {
      if (audioTrack)
        audioTrack.enabled =
          (turn?.current?.email === email || !meetingStarted) && !prev.micOn;

      return {
        micOn: !prev.micOn,
        videoOn: prev.videoOn,
      };
    });
  };

  const toggleVideo = () => {
    const stream = localStream;
    const videoTrack = stream.getTracks().find((t) => t.kind === "video");

    setLocalControl((prev) => {
      videoTrack.enabled = !prev.videoOn;

      return {
        micOn: prev.micOn,
        videoOn: !prev.videoOn,
      };
    });
  };

  const handleOnStartMeeting = () => {
    socket.emit("start");
  };

  const handleRequestMoreTime = () => {
    socket.emit("requestMoreTime");
  };

  const onMoreRequestAccepted = (values, { setSubmitting }) => {
    setTimeout(() => setSubmitting(false), 1500);
    socket.emit("moreTimeRequestAccepted", {
      of: requestedMoreTime.id,
      timeInMinutes: values.timeInMinutes,
    });
  };

  const onMoreRequestDecline = () => {
    socket.emit("moreTimeRequestDecline");
  };

  if (!("RTCPeerConnection" in window)) {
    return (
      <div className="bg-white h-screen flex flex-col justify-center items-center">
        <p>Your browser doesn't support WebRTC</p>
      </div>
    );
  }

  if (!localStream || !isConnected) {
    return (
      <div className="bg-white h-screen flex flex-col justify-center items-center">
        {!localStream && <p>Getting media stream</p>}
        {localStream && !isConnected && <p>Connecting to the server</p>}
        <LinearProgress className="w-10/12" />
      </div>
    );
  }

  return (
    <div className="bg-white md:h-screen flex flex-col md:flex-row gap-5 p-6">
      <div className="flex-1 bg-gray-200 shadow-lg ring-1 ring-gray-300 rounded-lg flex flex-col gap-5 p-7 md:max-h-screen">
        <MeetingHeader
          meetingId={meetingId}
          meetingStarted={meetingStarted}
          turn={turn}
        />
        <VideoContainer
          currentTurnEmail={turn?.current?.email}
          peers={[
            { user: { name: name, email }, mediaStream: localStream },
            ...peers,
          ]}
        />
        {supportSpeechRecognition && (
          <Transcription
            record={
              (turn?.current?.email === email || !meetingStarted) &&
              localControl.micOn
            }
          />
        )}
        <MeetingControls
          name={name}
          meetingId={meetingId}
          host={host}
          showStartButton={!meetingStarted}
          showRequestMoreTimeButton={
            turn?.current?.email === email && !requestedMoreTime
          }
          micOn={localControl.micOn}
          toggleMic={toggleMic}
          videoOn={localControl.videoOn}
          toggleVideo={toggleVideo}
          onStartMeeting={handleOnStartMeeting}
          onRequestMoreTime={handleRequestMoreTime}
        />
      </div>
      <Notes />
      <RequestMoreTime
        show={!!(requestedMoreTime && host)}
        onAccept={onMoreRequestAccepted}
        onDecline={onMoreRequestDecline}
      />
    </div>
  );
}
