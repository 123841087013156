import NewMeetingForm from "../components/NewMeetingForm";
import logo from "../assets/app_icon.jpeg";

export default function NewMeeting() {
  return (
    <div className="backdrop-blur h-screen flex flex-col overflow-auto gap-6 pt-24">
      <img
        src={logo}
        className="w-64 rounded-lg shadow self-center"
        alt="logo"
      />

      <div className="bg-white shadow-xl ring-1 ring-gray-900/5 mx-auto min-w-80 lg:w-4/12 md:w-8/12 max-w-10/12 p-7 pt-10 rounded">
        <h1 className="text-2xl mb-5">Create new meeting</h1>

        <NewMeetingForm />
      </div>
    </div>
  );
}
